export { default } from "./[uid]";
// import { queryParams } from './[uid]'
import { Client } from "../prismic1";
import { useGetStaticProps } from "next-slicezone/hooks";
import { fetchAdditionalData } from "../utils/fetch-additional-data";
import { getLanguage } from "../lib/getLanguage";

export async function getStaticProps({
  preview = null,
  previewData = {},
  params,
  locale,
}) {
  const client = Client();
  const languageObject = await getLanguage(locale);
  const language = await languageObject.prismicLanguage.toLowerCase();
  const sliceMachine = useGetStaticProps({
    client,
    params: {
      fetchLinks: [
        "project_category.icon",
        "project_category.title",
        "project.featured_image",
        "project.title",
        "project.project_category",
        "blog_post.title",
        "blog_post.featured_image",
        "blog_post.date",
        "blog_post.blog_topics",
        "blog_post.blog_topics.topic_name",
        "person.name",
        "person.title",
        "person.person_type",
        "person.portrait_image",
        "person.full_bio",
        "endorsement.endorser_name",
        "endorsement.endorser_title",
        "endorsement.endorsement",
        "endorsement.endorser_profile_image",
      ],
      lang: language,
    },
    type: "page",
    uid: "homepage",
  });

  const { props } = await sliceMachine({ preview, previewData, params });

  const additionalData = await fetchAdditionalData(
    props.slices,
    "homepage",
    language
  );
  return {
    props: {
      ...props,
      additionalData,
    },
  };
}
