import { useEffect } from 'react';
import { Client } from '../prismic1';
import Prismic from 'prismic-javascript';
import SliceZone from 'next-slicezone';
import { useGetStaticProps } from 'next-slicezone/hooks';
import { fetchAdditionalData } from '../utils/fetch-additional-data';
import { AdditionalDataProvider } from '../utils/additional-data-context';
import { useLayout } from '../utils/layout-context';
import resolver from '../sm-resolver.js';
import { getLanguage, getExtensionLanguage } from '../lib/getLanguage';
import errorService from '../services/error/error-service';
import Head from '../components/Head';

const Page = (props) => {
  const { data, url, locale, metaData } = props;
  const { setHeaderColor } = useLayout();

  useEffect(() => {
    if (data) setHeaderColor(data.headercolor);
  }, [data]);

  return (
    <>
      <Head data={data} canonical={url} />
      <AdditionalDataProvider value={props.additionalData}>
        <SliceZone {...props} resolver={resolver} />
      </AdditionalDataProvider>
    </>
  );
};

export async function getStaticProps({
  preview = null,
  previewData = {},
  params,
  locale,
}) {
  const client = Client();
  const languageObject = getLanguage(locale);
  const language = await languageObject.prismicLanguage.toLowerCase();
  const sliceMachine = useGetStaticProps({
    client,
    params: {
      fetchLinks: [
        'project_category.icon',
        'project_category.title',
        'project.featured_image',
        'project.title',
        'project.project_category',
        'blog_post.title',
        'blog_post.featured_image',
        'blog_post.date',
        'blog_post.blog_topics',
        'person.name',
        'person.title',
        'person.person_type',
        'person.portrait_image',
        'person.full_bio',
        'endorsement.endorser_name',
        'endorsement.endorser_title',
        'endorsement.endorsement',
        'endorsement.endorser_profile_image',
      ],
      lang: language,
    },
    type: 'page',
    uid: ({ params }) => params.uid,
  });

  const { props } = await sliceMachine({ preview, previewData, params });
  const additionalData = await fetchAdditionalData(
    props.slices,
    params.uid,
    language
  );
  const metaData = await client.getByUID('page', params.uid, {
    lang: language,
  });

  if (!metaData?.data) {
    return errorService.notFoundResponse;
  }

  return {
    notFound: !metaData?.data,
    props: {
      ...props,
      additionalData,
      locale,
      metaData,
    },
  };
}

export async function getStaticPaths() {
  const client = Client();
  const allPages = await client.query(
    Prismic.Predicates.at('document.type', 'page'),
    { lang: '*', pageSize: 100 }
  );

  const filteredResults = allPages.results.filter(
    (page) => page.uid !== 'homepage'
  );

  const paths = filteredResults.map((node) => {
    const languageObject = getExtensionLanguage(node.lang);
    const language = languageObject.prismicLanguage.toLowerCase();
    return {
      params: {
        uid: node.uid,
      },
      locale: language,
    };
  });

  return {
    paths,
    fallback: false,
  };
}
export default Page;
